p {
    margin: 0
}

/* [data-id="summary"].MuiDataGrid-row { */

/*    font-weight: bold; */

/* } */

[data-id="summary"].mui-data-grid-row {
    font-weight: bold;
}

.bold {
    font-weight: bold;
}

.total-row {
    font-weight: bold;
    background-color: #f9fafb;
}

.borderLeft {
    border-left: 2px solid #ccc; /* Customize the color and width as needed */
}

.borderRight {
    border-right: 2px solid #ccc; /* Customize the color and width as needed */
}

.borderLeftRight {
    border-left: 2px solid #ccc; /* Customize the color and width as needed */
    border-right: 2px solid #ccc; /* Customize the color and width as needed */
}

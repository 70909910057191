/* Example CSS to style react-datepicker */

/* .react-datepicker { */

/*    font-size: 16px; */

/*    font-weight: 600; */

/*    border: none; */

/* } */

/* .react-datepicker__header { */

/*    background-color: #fff; */

/*    color: #27272a; */

/*    border-bottom: none; */

/*    font-weight: 600; */

/*    font-size: 16px; */

/* } */

/* .react-datepicker__day-names { */

/*    font-size: 12px; */

/*    font-weight: 500; */

/*    color: #27272a; */

/*    padding-top: 10px; */

/* } */

/* .react-datepicker__day, */

/* .react-datepicker__time-name, */

/* .react-datepicker__time { */

/*    color: #333; */

/* } */

/* .react-datepicker__navigation-icon::before { */

/*    border-color: #27272a; */

/*    top: 10px; */

/* } */

/* .react-datepicker__year .react-datepicker__year-text { */

/*    padding: 5px; */

/*    color: #27272a; */

/* } */

/* .react-datepicker__month .react-datepicker__month-text { */

/*    padding: 5px; */

/*    color: #27272a; */

/* } */

/* .react-datepicker__day .react-datepicker__day-text { */

/*    padding: 5px; */

/*    color: #27272a; */

/* } */

/* .react-datepicker__day--selected { */

/*    background-color: #064b7c; */

/*    color: #fff; */

/* } */

/* .react-datepicker__day--keyboard-selected { */

/*    background-color: #064b7c; */

/*    color: #fff; */

/* } */

/* .react-datepicker__month--selected { */

/*    background-color: #064b7c; */

/*    color: #fff; */

/* } */

/* .react-datepicker__month--keyboard-selected { */

/*    background-color: #064b7c; */

/*    color: #fff; */

/* } */

/* .react-datepicker__day--selected, */

/* .react-datepicker__day--in-selecting-range, */

/* .react-datepicker__day--in-range, */

/* .react-datepicker__month-text--selected, */

/* .react-datepicker__month-text--in-selecting-range, */

/* .react-datepicker__month-text--in-range, */

/* .react-datepicker__quarter-text--selected, */

/* .react-datepicker__quarter-text--in-selecting-range, */

/* .react-datepicker__quarter-text--in-range, */

/* .react-datepicker__year-text--selected, */

/* .react-datepicker__year-text--in-selecting-range, */

/* .react-datepicker__year-text--in-range { */

/*    background-color: #064b7c; */

/*    color: #fff; */

/* } */

/* .react-datepicker__day--disabled { */

/*    color: #ccc; */

/*    cursor: default; */

/* } */

/* .react-datepicker__day--disabled:hover { */

/*    background-color: transparent; */

/*    color: #ccc; */

/*    cursor: default; */

/* } */

/* .react-datepicker__month--disabled, */

/* .react-datepicker__quarter--disabled { */

/*    color: #ccc; */

/*    pointer-events: none; */

/* } */

/* .react-datepicker__year--disabled, */

/* .react-datepicker__quarter--disabled { */

/*    color: #ccc; */

/*    pointer-events: none; */

/* } */

/* .react-datepicker__month-text--disabled, */

/* .react-datepicker__quarter-text--disabled, */

/* .react-datepicker__year-text--disabled { */

/*    color: #ccc; */

/*    pointer-events: none; */

/* } */

.react-datepicker__year-wrapper {
    min-width: 210px;
}
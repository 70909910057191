.tooltip {
    position: absolute;
    text-align: center;
    min-width: 150px;
    min-height: 50px;
    padding: 2px;
    font: 12px sans-serif;
    background-color: #FFF;
    border: 0;
    border-radius: 3px;
    pointer-events: none;
    box-shadow: 0 4px 6px -1px #0000001A;
}